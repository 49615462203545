import { Col, Row } from 'antd'
import React, { ReactElement, useContext } from 'react'
import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {
  ContentContainer,
  SectionWrapper,
  PurifyLink,
  CSSlink,
} from '~components/homepage/styled/shared'

import { mediaQueries } from '~components/homepage/styled/constants'

import LanguageContext from '~components/homepage/context/language-context'
import { HeaderRedirectLink } from '../styled/header'

const CenterContent = styled(Col)`
  text-align: center;
  font-size: 7px;

  p {
    display: inline-block;
    margin: 0 5px;

    &:last-child {
      ::after {
        display: none;
      }
    }

    &::after {
      content: '|';
      display: inline-block;
      margin-left: 10px;
    }
  }

  @media (max-width: ${mediaQueries.small}) {
    p {
      &:first-child {
        margin-top: 20px;

        @media (max-width: ${mediaQueries.small}) {
          & {
            margin-top: 0;
          }
        }
      }

      display: block;
      text-align: center;

      &::after {
        display: none;
      }
    }
  }
`

const FooterWrap = styled.div`
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 220px;
  transition: 0.6s;

  @media (max-width: ${mediaQueries.small}) {
    & {
      display: none;

      * > {
        display: none;
      }
    }
  }
`

const Item = styled.div`
  font-size: 10px;
  margin: 0 15px;
  transition: 0.6s;
`

const FooterSection = (): ReactElement => {
  const { t } = useContext(LanguageContext)

  return (
    <SectionWrapper bgColor="#F7F5F1">
      <ContentContainer padding="20px">
        <FooterWrap>
          <Item>
            <PurifyLink>
              <AnchorLink href="#gridKnife">{t('header.gridKnife')}</AnchorLink>
            </PurifyLink>
          </Item>
          <Item>
            <PurifyLink>
              <AnchorLink href="#about">{t('header.about')}</AnchorLink>
            </PurifyLink>
          </Item>
          <Item>
            <PurifyLink>
              <HeaderRedirectLink href="/articles">
                {t('header.articles')}
              </HeaderRedirectLink>
            </PurifyLink>
          </Item>
          <Item>
            <PurifyLink>
              <HeaderRedirectLink href="/workshop">
                {t('header.workshop')}
              </HeaderRedirectLink>
            </PurifyLink>
          </Item>
          <Item>
            <PurifyLink>
              <AnchorLink href="#faq">{t('header.faq')}</AnchorLink>
            </PurifyLink>
          </Item>
          <Item>
            <PurifyLink>
              <AnchorLink href="#contact">{t('header.contact')}</AnchorLink>
            </PurifyLink>
          </Item>
          <Item>
            <PurifyLink>
              <CSSlink
                target="_blank"
                rel="noopener noreferrer"
                href="https://facebook.com/noze.klosy/"
              >
                FACEBOOK
              </CSSlink>
            </PurifyLink>
          </Item>
          <Item>
            <PurifyLink>
              <CSSlink
                target="_blank"
                rel="noopener noreferrer"
                href="https://instagram.com/klosy_knives/"
              >
                INSTAGRAM
              </CSSlink>
            </PurifyLink>
          </Item>
        </FooterWrap>
        <Row>
          <CenterContent sm={24} md={24}>
            <p>© KŁOSY PIOTR JĘDRAS {new Date().getFullYear()} </p>
            <p>
              web design:{' '}
              <CSSlink href="https://dominikawysoglad.pl/me">
                Dominika Wysogląd
              </CSSlink>
            </p>
            <p>site by: Jan Mirecki & Grigorii Ukrainskii</p>
          </CenterContent>
        </Row>
      </ContentContainer>
    </SectionWrapper>
  )
}

export default FooterSection
