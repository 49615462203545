import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { KlosySVGId } from '~components/homepage/svg/types/svg'

import LanguageContext from '~components/homepage/context/language-context'
import {
  HeaderLink,
  Container,
  MobileHeaderWrap,
  HamburgerMenu,
  MobileItem,
  LogoLink,
  Link,
  LangSwitcherWrapper,
  LangSwitcher,
  HeaderWrap,
  Item,
  HeaderRedirectLink,
  HeaderGatsbyLink,
} from '../styled/header'

interface Props {
  onSwitchLang: Function
}

const LANG_SWITCHER_BLACK_LIST = ['workshop']

const Header = ({ onSwitchLang }: Props): ReactElement => {
  const { t, lang } = useContext(LanguageContext)

  const [headerMode, setHeaderMode] = useState<string>('')
  const [folded, foldMenu] = useState<string>('')
  const [blockLangSwitcher, setLangSwitcher] = useState<boolean>(true)

  useEffect((): (() => void) => {
    const handleScroll = (): void => {
      if (window.scrollY > 10) setHeaderMode('small')
      else if (window.scrollY < 10) setHeaderMode('')
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return (): void => window.removeEventListener('scroll', handleScroll)
  }, [])

  let isNotHomePage = false

  if (typeof window !== `undefined`) {
    isNotHomePage = window.location.pathname !== '/'
  }

  useEffect(() => {
    const currentUrl = window.location.pathname
    const isAllowed = LANG_SWITCHER_BLACK_LIST.map(url =>
      currentUrl.includes(url)
    ).some(v => v)

    setLangSwitcher(isAllowed)
  }, [])

  const renderLink = (hash: string, locKey: string): React.ReactElement =>
    isNotHomePage ? (
      <HeaderGatsbyLink to={`/${hash}`}>{t(locKey)}</HeaderGatsbyLink>
    ) : (
      <HeaderLink onClick={(): void => foldMenu('')} href={hash}>
        {t(locKey)}
      </HeaderLink>
    )

  return (
    <Container className={`${headerMode}`}>
      <MobileHeaderWrap className={`${headerMode} ${folded}`}>
        <span
          onClick={(): void => foldMenu('folded')}
          onKeyPress={(): void => {}}
          role="button"
          tabIndex={0}
        >
          <HamburgerMenu
            svgProps={[KlosySVGId.hamburger, 'klosy-knives', 18, 15]}
            className="hamburger-menu"
          />
        </span>

        <span
          onClick={(): void => foldMenu('')}
          onKeyPress={(): void => {}}
          role="button"
          tabIndex={0}
        >
          <HamburgerMenu
            svgProps={[KlosySVGId.cross, 'klosy-knives', 16, 16]}
            className="hamburger-close"
          />
        </span>

        <MobileItem className="logo">
          <HeaderLink href="#home" onClick={(): void => foldMenu('')} noAnim>
            <LogoLink
              svgProps={[KlosySVGId.klosyLogo, 'klosy-knives-logo', 110, 55]}
            />
          </HeaderLink>
        </MobileItem>

        <MobileItem className="knife-grid-link">
          {renderLink('#gridKnife', 'header.gridKnife')}
        </MobileItem>
        <MobileItem>{renderLink('#about', 'header.about')}</MobileItem>
        <MobileItem>{renderLink('#faq', 'header.faq')}</MobileItem>
        <MobileItem>{renderLink('#contact', 'header.contact')}</MobileItem>
        <MobileItem>
          <HeaderRedirectLink href="/articles">
            {t('header.articles')}
          </HeaderRedirectLink>
        </MobileItem>
        <MobileItem>
          <HeaderRedirectLink href="/store">
            {t('header.store')}
          </HeaderRedirectLink>
        </MobileItem>

        <MobileItem>
          <HeaderRedirectLink href="/workshop">
            {t('header.workshop')}
          </HeaderRedirectLink>
        </MobileItem>

        <MobileItem className="facebook">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://facebook.com/noze.klosy/"
            onClick={(): void => foldMenu('')}
          >
            FACEBOOK
          </Link>
        </MobileItem>
        <MobileItem>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/klosy_knives/"
            onClick={(): void => foldMenu('')}
          >
            INSTAGRAM
          </Link>
        </MobileItem>

        <MobileItem className="lang-switcher">
          <LangSwitcherWrapper>
            <LangSwitcher
              activeLang={lang}
              defaultLang="PL"
              onClick={(): void => {
                onSwitchLang('PL')
                foldMenu('')
              }}
            >
              <span>PL</span>
            </LangSwitcher>
            |
            <LangSwitcher
              activeLang={lang}
              defaultLang="EN"
              onClick={(): void => {
                onSwitchLang('EN')
                foldMenu('')
              }}
            >
              <span>EN</span>
            </LangSwitcher>
          </LangSwitcherWrapper>
        </MobileItem>
      </MobileHeaderWrap>

      <HeaderWrap className={`${headerMode}`}>
        <Item className="logo-mini">
          <HeaderLink href="#home" noAnim>
            <LogoLink
              svgProps={[KlosySVGId.logoMini, 'klosy-knives-logo-mini', 10, 24]}
            />
          </HeaderLink>
        </Item>
        <Item>{renderLink('#gridKnife', 'header.gridKnife')}</Item>
        <Item>{renderLink('#about', 'header.about')}</Item>
        <Item>{renderLink('#gridKnife', 'header.order')}</Item>
        <Item>
          <HeaderRedirectLink href="/store">
            {t('header.store')}
          </HeaderRedirectLink>
        </Item>
        <Item className="logo">
          <HeaderLink href="#home" noAnim>
            <LogoLink
              svgProps={[KlosySVGId.klosyLogo, 'klosy-knives-logo', 110, 55]}
            />
          </HeaderLink>
        </Item>
        <Item>
          <HeaderRedirectLink href="/articles">
            {t('header.articles')}
          </HeaderRedirectLink>
        </Item>
        <Item>
          <HeaderRedirectLink href="/workshop">
            {t('header.workshop')}
          </HeaderRedirectLink>
        </Item>
        <Item>{renderLink('#faq', 'header.faq')}</Item>
        <Item>{renderLink('#contact', 'header.contact')}</Item>

        <Item className="translate-switcher">
          {!blockLangSwitcher ? (
            <LangSwitcherWrapper>
              <LangSwitcher
                activeLang={lang}
                defaultLang="PL"
                onClick={(): void => onSwitchLang('PL')}
              >
                <span>PL</span>
              </LangSwitcher>
              |
              <LangSwitcher
                activeLang={lang}
                defaultLang="EN"
                onClick={(): void => onSwitchLang('EN')}
              >
                <span>EN</span>
              </LangSwitcher>
            </LangSwitcherWrapper>
          ) : (
            <div style={{ width: 76 }} />
          )}
        </Item>
      </HeaderWrap>
    </Container>
  )
}

export default Header
