import React, { ReactElement, FC } from 'react'
import { Helmet as HelmetRaw } from 'react-helmet'

export const Helmet: FC = (): ReactElement => {
  return (
    <HelmetRaw>
      <meta charSet="utf-8" />
      <title>Kłosy - ręcznie robione noże kuchenne dopasowane do Ciebie</title>
      <link rel="canonical" href="https://klosy.pl" />
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-142305690-1"
      ></script>
      <script
        async
        defer
        crossOrigin="anonymous"
        src="https://connect.facebook.net/en_US/sdk.js"
      ></script>
      <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-142305690-1');
      `}</script>
      <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-P4Q2P62');
      `}</script>

      <script>
        {`
      window.fbAsyncInit = function() {
        FB.init({
          appId            : '1543057512549033',
          autoLogAppEvents : true,
          xfbml            : true,
          version          : 'v15.0'
        });
      };
    `}
      </script>
      <meta
        name="description"
        content="Nóż szefa kuchni, wykrawacz i do obierania to klasyki. Wybierz tasaki, do chleba, filetowania.. i stwórz z nami swój zestaw noży kuchennych"
      />
      <meta
        name="keywords"
        content="noże kuchenne, manufaktura Kłosy, Warszawa, ręcznie robione, rzemieślnicze"
      />
      <meta
        name="facebook-domain-verification"
        content="14m3ldrhekstv3mdx305697cls41b6"
      />
      <noscript>{`
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P4Q2P62" height="0" width="0" style={{display: 'none', visibility: 'hidden'}}></iframe>
      `}</noscript>
    </HelmetRaw>
  )
}
