import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { AnchorLink as GatsbyLink } from 'gatsby-plugin-anchor-links'
import {
  palette,
  letterSpacing,
  mediaQueries,
} from '~components/homepage/styled/constants'

import { SVGImage, SVGImageProps } from '~components/homepage/svg/SVGImage'

import { PurifyLink } from '~components/homepage/styled/shared'

export const LogoLink = styled(SVGImage)<SVGImageProps>`
  max-width: 100%;
  fill: ${palette.lightBlue};
  transition: opacity 0.2s ease-in-out;

  :hover {
    opacity: 0.8;
  }
`

export const LangSwitcherWrapper = styled.div`
  a,
  span {
    letter-spacing: ${letterSpacing};
    margin: 0 5px;
    cursor: pointer;
  }

  @media (max-width: ${mediaQueries.small}) {
    & {
      position: inherit;
      padding: 0;
    }
  }
`

interface SwitcherProps {
  activeLang: string
  defaultLang: string
}

export const LangSwitcher = styled(PurifyLink)`
  font-family: ${({ activeLang, defaultLang }: SwitcherProps): string =>
      activeLang === defaultLang ? 'Averta-Bold' : 'Averta-Regular'},
    sans-serif;

  a,
  span {
    :after {
      height: 0.5em;
    }
  }
`

export const Container = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  transition: 0.6s;

  &.small {
    background: ${palette.beige};
  }
`

export const HeaderWrap = styled.div`
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 125px;
  transition: 0.6s;

  @media (max-width: ${mediaQueries.small}) {
    & {
      display: none;

      * > {
        display: none;
      }
    }
  }

  &.small {
    height: 50px;

    .logo-mini {
      flex-grow: inherit;
      margin-right: 60px;
      opacity: 1;
      margin-left: 28px;
    }

    .logo {
      width: 0;
      margin: 0;
    }
  }
`

interface HeaderLinkProps {
  noAnim?: boolean
}

export const HeaderGatsbyLink = styled(GatsbyLink)`
  color: ${palette.navyBlue};
  letter-spacing: ${letterSpacing};
  display: inline-block;
  position: relative;
  padding: 15px 0;

  :hover {
    color: ${palette.navyBlue};
  }

  :hover::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  :after {
    content: '';
    position: absolute;
    left: 0;
    display: inline-block;
    height: 0.8em;
    width: 100%;
    border-bottom: 1px solid ${palette.navyBlue};
    margin-top: 10px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }
`

export const HeaderLink = styled(AnchorLink)<HeaderLinkProps>`
  color: ${palette.navyBlue};
  letter-spacing: ${letterSpacing};
  display: inline-block;
  position: relative;
  padding: 15px 0;

  :hover {
    color: ${palette.navyBlue};
  }

  :hover::after {
    opacity: ${({ noAnim }): number => (noAnim ? 0 : 1)};
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  :after {
    content: '';
    position: absolute;
    left: 0;
    display: inline-block;
    height: 0.8em;
    width: 100%;
    border-bottom: 1px solid ${palette.navyBlue};
    margin-top: 10px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }
`

export const HeaderRedirectLink = styled.a`
  color: ${palette.navyBlue};
  letter-spacing: ${letterSpacing};
  display: inline-block;
  position: relative;
  padding: 15px 0;

  :hover {
    color: ${palette.navyBlue};
  }

  :hover::after {
    opacity: ${({ noAnim }): number => (noAnim ? 0 : 1)};
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  :after {
    content: '';
    position: absolute;
    left: 0;
    display: inline-block;
    height: 0.8em;
    width: 100%;
    border-bottom: 1px solid ${palette.navyBlue};
    margin-top: 10px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }
`

export const Item = styled.div`
  font-size: 10px;
  margin: 0 14px;
  transition: 0.6s;

  &.logo {
    margin: 0 35px;
    width: 110px;
    overflow: hidden;
  }

  &.logo-mini {
    flex-grow: 1;
    display: flex;
    margin-left: 0;
    opacity: 0;
    margin-right: 95px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.translate-switcher {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 18px;
  }
`

export const MobileHeaderWrap = styled.div`
  width: 100%;
  display: none;
  height: 127px;
  overflow: hidden;
  padding-top: 40px;
  transition: 0.3s;

  .hamburger-close {
    display: none;
    top: 24px;
  }

  &.small {
    height: 40px;
    padding-top: 0;

    .hamburger-menu {
      top: 13px;
    }

    .hamburger-close {
      display: none;
      top: 13px;
    }

    .logo {
      margin-top: -3px;

      a {
        padding: 0px;
      }
    }
  }

  &.folded {
    height: 100vh;
    background: ${palette.beige};

    .hamburger-menu {
      display: none;
    }

    .hamburger-close {
      display: block;
    }
  }

  @media (max-width: ${mediaQueries.small}) {
    & {
      display: flex;
      flex-direction: column;
    }
  }

  .knife-grid-link,
  .facebook {
    margin-top: auto;
  }

  .knife-grid-link {
    padding-top: 10px;
  }
`

export const Link = styled.a`
  color: ${palette.darkGray};

  :hover {
    color: ${palette.darkGray};
  }
`

export const MobileItem = styled.div`
  font-size: 10px;
  margin: 0 14px;
  transition: 0.3s;
  text-align: center;

  &.lang-switcher {
    margin-bottom: 50px;

    & > div {
      padding: 12px 0;
    }
  }
`

export const HamburgerMenu = styled(SVGImage)<SVGImageProps>`
  min-height: 15px;
  position: absolute;
  right: 22px;
  top: 24px;
  transition: 0.6s;
  cursor: pointer;
`
